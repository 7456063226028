import "./styles.css";
import { Difficulty, getPuzzle } from "./lib/sudoku";

const GRID_SIZE = 9;

let sudokuData: {
  puzzle: string;
  solution: string;
  difficulty: string;
};
let selectedCell: HTMLElement | null = null;
let noteMode = false;

type UserSolution = {
  answer: string;
  notes: string[];
};
let userSolution: UserSolution[];
let numPadClicks: { [number: string]: number } = {};

function prefillNumPadClicks() {
  numPadClicks = {};

  userSolution.forEach((cell) => {
    if (cell.answer !== "-") {
      increaseNumPadValue(cell.answer);
    }
  });
}

function createSudokuGrid(
  containerId: string,
  puzzle: string,
  userSolution: UserSolution[],
) {
  const container = document.getElementById(containerId);

  prefillNumPadClicks();

  if (!container) {
    alert("Container not found");
    return;
  }

  container.innerText = ""; // Clear previous grid

  for (let index = 0; index < puzzle.length; index++) {
    const cell = document.createElement("div");
    cell.classList.add("cell");

    const row = Math.floor(index / 9);
    const col = index % 9;

    // Thick borders for 3x3 blocks
    if ((col + 1) % 3 === 0 && col !== 8) {
      cell.classList.add("thick-right");
    }
    if ((row + 1) % 3 === 0 && row !== 8) {
      cell.classList.add("thick-bottom");
    }

    // restoreNotesToCells(cell, index);

    // Set content and attributes based on the puzzle and userSolution
    if (puzzle[index] !== "-") {
      cell.textContent = puzzle[index];
      cell.classList.add("readonly");

      restoreNotesToCells(cell, index);
    } else {
      cell.setAttribute("contenteditable", "false");

      if (userSolution[index].answer !== "-") {
        cell.textContent = userSolution[index].answer;
      } else {
        restoreNotesToCells(cell, index);
      }

      cell.setAttribute("contenteditable", "false");
      cell.addEventListener("click", () => {
        if (selectedCell) {
          selectedCell.classList.remove("selected");
        }
        selectedCell = cell;
        cell.classList.add("selected");
      });
    }

    container.appendChild(cell);
  }
}

// Checks if there is a puzzle already ongoing in the local storage
function loadPuzzle() {
  const puzzle = localStorage.getItem("puzzle");
  if (puzzle) {
    const loadedData = JSON.parse(puzzle);
    sudokuData = loadedData.sudokuData;
    userSolution = loadedData.userSolution.map((cell: UserSolution) => ({
      answer: cell.answer,
      notes: cell.notes || [],
    }));

    return true;
  }

  return false;
}

// Saves the puzzle in the local storage
function savePuzzle() {
  localStorage.setItem("puzzle", JSON.stringify({ sudokuData, userSolution }));
}

function clearPuzzle() {
  localStorage.removeItem("puzzle");
}

function increaseNumPadValue(value: string) {
  if (numPadClicks[value]) {
    numPadClicks[value]++;
  } else {
    numPadClicks[value] = 1;
  }

  const numpadButton = document.querySelector(`[data-value="${value}"]`);

  if (numPadClicks[value] >= 9) {
    numpadButton?.classList.add("disabled");
  } else {
    numpadButton?.classList.remove("disabled");
  }
}

function decreaseNumPadValue(value: string) {
  if (numPadClicks[value]) {
    numPadClicks[value]--;
  } else {
    numPadClicks[value] = 0;
  }

  const numpadButton = document.querySelector(`[data-value="${value}"]`);

  if (numPadClicks[value] >= 9) {
    numpadButton?.classList.add("disabled");
  } else {
    numpadButton?.classList.remove("disabled");
  }
}

function handleNumpadClick(event: Event) {
  const target = event.target as HTMLElement;
  const value = target.getAttribute("data-value");

  // Disallow to edit values that are already preset by the puzzle
  if (!value || selectedCell?.classList.contains("readonly")) {
    return;
  }

  if (selectedCell) {
    const index = Array.prototype.indexOf.call(
      selectedCell.parentNode?.children,
      selectedCell,
    );

    if (value === "delete") {
      if (userSolution[index].answer !== "-") {
        decreaseNumPadValue(userSolution[index].answer);
      }

      selectedCell.textContent = "";
      userSolution[index].answer = "-";

      restoreNotesToCells(selectedCell, index);
    } else {
      if (noteMode) {
        addNoteToCell(selectedCell, value, index);
      } else {
        if (!numPadClicks[value] || numPadClicks[value] < 9) {
          if (userSolution[index].answer !== value) {
            const currentValue = userSolution[index].answer;

            if (currentValue !== "-") {
              decreaseNumPadValue(userSolution[index].answer);
            }

            selectedCell.textContent = value;
            userSolution[index].answer = value;

            increaseNumPadValue(value);
          }
        }
      }
    }

    savePuzzle();
    checkSolution(false);
  }
}

function restoreNotesToCells(cell: HTMLElement, index: number) {
  userSolution[index].notes.forEach((value) => {
    const note = document.createElement("div");

    note.classList.add("note", `note-${value}`);
    note.textContent = value;
    cell.appendChild(note);
  });
}

function addNoteToCell(cell: HTMLElement, value: string, index: number) {
  const noteIndex = userSolution[index].notes.indexOf(value);

  if (noteIndex === -1) {
    userSolution[index].notes.push(value);
    const note = document.createElement("div");

    note.classList.add("note", `note-${value}`);
    note.textContent = value;
    cell.appendChild(note);
  } else {
    userSolution[index].notes.splice(noteIndex, 1);
    const existingNote = cell.querySelector(`.note-${value}`);

    if (existingNote) {
      existingNote.remove();
    }
  }
}

function toggleNoteMode() {
  noteMode = !noteMode;

  const noteButton = document.getElementById("note-toggle");

  if (noteMode) {
    noteButton?.classList.add("active");
    return;
  }

  noteButton?.classList.remove("active");
}

function checkSolution(interactive: boolean) {
  let correct = true;

  for (let i = 0; i < userSolution.length; i++) {
    const cell = document.querySelector(
      `#sudoku-container .cell:nth-child(${i + 1})`,
    ) as HTMLElement;

    if (userSolution[i].answer === "-") {
      correct = false;
      continue;
    }

    if (!cell.classList.contains("readonly")) {
      if (userSolution[i].answer !== sudokuData.solution[i]) {
        if (interactive) {
          cell.classList.add("incorrect");
          setTimeout(() => {
            cell.classList.remove("incorrect");
          }, 5000);
        }

        correct = false;
      } else {
        cell.classList.remove("incorrect");
      }
    }
  }

  if (correct) {
    alert("Puzzle solved!");
    clearPuzzle();

    const audio = document.getElementById("winAudio");
    (audio as HTMLAudioElement)
      ?.play()
      .catch(console.error)
      .then(() => console.log("music played"));
  }
}

function generatePuzzle(difficulty: Difficulty, resume?: boolean) {
  const puzzle = resume ? loadPuzzle() : false;

  if (!sudokuData || !resume || !puzzle) {
    sudokuData = getPuzzle(difficulty);

    // Initialize userSolution with empty data
    userSolution = sudokuData.puzzle.split("").map((answer) => ({
      answer,
      notes: [],
    }));

    // const solution = `-${sudokuData.solution.slice(1)}`.split("");
    // userSolution = userSolution.map((answer, index) => ({
    //   answer: solution[index],
    //   notes: [],
    // }));

    savePuzzle();
  }

  createSudokuGrid("sudoku-container", sudokuData.puzzle, userSolution);
}

document.addEventListener("DOMContentLoaded", () => {
  const easyButton = document.getElementById("easy");
  const mediumButton = document.getElementById("medium");
  const hardButton = document.getElementById("hard");
  const expertButton = document.getElementById("expert");

  easyButton?.addEventListener("click", () => generatePuzzle("easy"));
  mediumButton?.addEventListener("click", () => generatePuzzle("medium"));
  hardButton?.addEventListener("click", () => generatePuzzle("hard"));
  expertButton?.addEventListener("click", () => generatePuzzle("expert"));

  // Generate an initial puzzle
  generatePuzzle("easy", true);

  const numpadContainer = document.getElementById("numpad-container");
  numpadContainer?.addEventListener("click", handleNumpadClick);

  const checkButton = document.getElementById("check-solution");
  checkButton?.addEventListener("click", () => {
    checkSolution(true);
  });

  const noteButton = document.getElementById("note-toggle");
  noteButton?.addEventListener("click", toggleNoteMode);

  window.addEventListener("keydown", function (event) {
    event.preventDefault();

    if (event.key === " ") {
      noteButton?.click();
    }

    // If the event is one of the arrow keys
    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowDown" ||
      event.key === "ArrowUp" ||
      event.key === "Backspace"
    ) {
      if (!selectedCell) {
        // Set the cell to the first div in the grid
        selectedCell = document.querySelector(".cell");
      }

      if (selectedCell) {
        if (event.key === "Backspace") {
          const deleteNumber: HTMLDivElement | null = document.querySelector(
            `[data-value="delete"]`,
          );
          deleteNumber?.click();
          return;
        }

        if (event.key === "ArrowRight") {
          const nextCell = selectedCell?.nextElementSibling;

          if (nextCell) {
            selectedCell?.classList.remove("selected");
            selectedCell = nextCell as HTMLElement;
            selectedCell.classList.add("selected");
          }

          return;
        }

        if (event.key === "ArrowLeft") {
          const prevCell = selectedCell?.previousElementSibling;

          if (prevCell) {
            selectedCell?.classList.remove("selected");
            selectedCell = prevCell as HTMLElement;
            selectedCell.classList.add("selected");
          }

          return;
        }

        if (event.key === "ArrowDown") {
          const currentIndex = Array.from(
            selectedCell?.parentNode?.children ?? [],
          ).indexOf(selectedCell);
          const nextIndex = currentIndex + GRID_SIZE;

          if (nextIndex < (selectedCell?.parentNode?.children?.length ?? 0)) {
            const nextCell = selectedCell?.parentNode?.children[
              nextIndex
            ] as HTMLElement;

            selectedCell?.classList.remove("selected");
            selectedCell = nextCell;
            selectedCell.classList.add("selected");
          }

          return;
        }

        if (event.key === "ArrowUp") {
          const currentIndex = Array.from(
            selectedCell?.parentNode?.children ?? [],
          ).indexOf(selectedCell);
          const prevIndex = currentIndex - GRID_SIZE;

          if (prevIndex >= 0) {
            const prevCell = selectedCell?.parentNode?.children[
              prevIndex
            ] as HTMLElement;

            selectedCell?.classList.remove("selected");
            selectedCell = prevCell;
            selectedCell.classList.add("selected");
          }

          return;
        }
      }
    }

    // Or if it is one of the numbers
    if (event.key >= "1" && event.key <= "9") {
      const numpadButton: HTMLDivElement | null = document.querySelector(
        `[data-value="${event.key}"]`,
      );
      numpadButton?.click();
    }
  });
});
